import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <footer>
            <p>Jack R. O'Neill</p>   
            <ul>
                <li><a href="https://www.linkedin.com/in/jack-o-neill-7b1476156/"><i class="fa fa-linkedin-square fa-2x"></i></a></li>
            </ul>
        </footer>
    )
}

export default Footer