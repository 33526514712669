import React from 'react';
import './about.css'
import SmallImageTop from '../../resources/tupe.webp';
import LargeImageTop from '../../resources/Maui2.webp';
import SmallImageBottom from '../../resources/Maui3.webp';
import LargeImageBottom from '../../resources/buffalo.webp';

const About = () => {
    return (
        <section id="about" className="about" href="About">
            <h3 class="title">About me:</h3>
            <p>I was born in New York, and followed my passion for surfing to Oahu.</p>
            <p>My faviorite things are, surfing, playing video games, playing card games, playing ping pong, playing with my cats, hanging out with my friends, spending time on my computer.</p>
            <hr></hr>
            <ul className="grid">
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + SmallImageTop +")"}}></li>
                <li className="large" loading="lazy" style={{backgroundImage: "url(" + LargeImageTop +")"}}></li>
                <li className="large" loading="lazy" style={{backgroundImage: "url(" + LargeImageBottom +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + SmallImageBottom +")"}}></li>
            </ul>
        </section>
    )
}
export default About;
