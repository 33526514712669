import React from 'react';

const QualificationPage = () => {
    return (
        <div class="workExp">
            <p class="job"><b>Programming/Languages:</b><br></br>
            C#, C++, Python, SQL, HTML, CSS, JavaScript, React framework.<br></br>
            </p>



            <p class="job"><b>Systems:</b><br></br>
            Windows, Mac, Linux, iOS, Android.<br></br>
            </p>

    
            <p class="job"><b>Experience administrating:</b><br></br>
            <ul>
            •	Microsoft Office 365
            <br></br>    •	Point of Sale.
            <br></br>    •	Restaurant Inventory solutions.
            <br></br>    •	OPUS Parking Equipment.
            <br></br>    •	Elastix PBX.
            <br></br>    •	Enterprise Antivirus.
            <br></br>    •	Mobile Device Management.
            <br></br>    •	Network Video Recording software and hardware.
            <br></br>    •	Security Patrol Solutions.<br></br>
            </ul>
            </p>
            <br></br>
        </div>
    )
}
export default QualificationPage;