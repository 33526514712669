import React, { Component } from 'react';
import Navbar from './components/navbar/navbar';
import Hero from './components/hero/hero';
import Portfolio from './components/portfolio/portfolio';
import About from './components/about/about';
import Resume from './components/resume/resume';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';
import Buffer from './components/buffer/buffer';
import MessageBoard from './components/messageboard/messageboard';
import Cats from './components/cats/cats';
import Hobbies from './components/hobbies/hobbies';
import './App.css';


class App extends Component {

  state = {
    homePage: true,
    messageBoardPage: false,
    catsPage: false,
    resumePage: false,
    hobbiesPage: false,
    isLoggedIn: false,
    riotLeagueAccount: [],
  }

  changeLoginHandler = () => {
    this.setState({
      isLoggedIn: true,
    })
  }

  changeLogoutHandler = () => {
    this.setState({
      isLoggedIn: false,
    })
  }

  changePageToHomeHandler = () => {
    this.setState({
      homePage: true,
      messageBoardPage: false,
      catsPage: false,
      resumePage: false,
      hobbiesPage: false,
    })
  }

  changePageToMessageBoardPage = () => {
    this.setState({
      homePage: false,
      messageBoardPage: true,
      catsPage: false,
      resumePage: false,
      hobbiesPage: false,
    })
  }

  changePageToCatsPage = () => {
    this.setState({
      homePage: false,
      messageBoardPage: false,
      catsPage: true,
      resumePage: false,
      hobbiesPage: false,
    })
  }

  changePageToResumePage = () => {
    this.setState({
      homePage: false,
      messageBoardPage: false,
      catsPage: false,
      resumePage: true,
      hobbiesPage: false,
    })
  }

  changePageToHobbiesPage = () => {
    this.setState({
      homePage: false,
      messageBoardPage: false,
      catsPage: false,
      resumePage: false,
      hobbiesPage: true,
    })
  }


  render() {

    const isHomePage = this.state.homePage;
    const isMessageBoardPage = this.state.messageBoardPage;
    const isCatsPage = this.state.catsPage;
    const isResumePage = this.state.resumePage;
    const isHobbiesPage = this.state.hobbiesPage;
    const isRiotLeagueAccount = this.state.riotLeagueAccount

    return (
      <div className="App">

        <Navbar
          home={this.changePageToHomeHandler}
          resume={this.changePageToResumePage}
          login={this.changeLoginHandler}
          logout={this.changeLogoutHandler}
          isLoggedIn={this.state.isLoggedIn}
        />

        {isHomePage ? (
          <div>
            <Hero/>
            <Portfolio
              messageBoard={this.changePageToMessageBoardPage}
              cats={this.changePageToCatsPage}
              hobbies={this.changePageToHobbiesPage}
              resume={this.changePageToResumePage}
              />
            <About />
            <Contact />
            <Footer />
          </div> ) : (null) }

        {isResumePage ? (
          <div>
            <Buffer />
              <Resume />
            <Footer />
          </div> ) : (null) }

        {isCatsPage ? (
          <div>
            <Buffer />
              <Cats />
            <Footer />
          </div> ) : (null) }

        {isMessageBoardPage ? (
          <div>
            <Buffer />
            <MessageBoard />
            <Footer />
          </div> ) : (null) }

        {isHobbiesPage ? (
          <div>
            <Buffer />
            <Hobbies />
            <Footer />
          </div> ) : (null) }

      </div>
    );
  }
}

export default App;
