import React from 'react';
import './contact.css';

const Contact = () => {
    return (
        <section id="Contact" class="Want to Know more?">
            <h3 class="title">Contact</h3>
            <p>Please contact me regarding any questions or if you would like to learn more.</p>
            <hr></hr>
            <p>(C): (516) 632-0509</p>
            <p>Email: Oneilljr01@mail.buffalostate.edu</p>
        </section>
    )
}
export default Contact