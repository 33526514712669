import React from 'react';

const WorkPage = () => {
    return(
        <div class="workExp">
            <p class="job"><b>System Administrator</b><br></br>
                Waikiki Business Plaza – Honolulu, HI<br></br>
                March 2018 - Present <br></br>
            </p>

            <p class="left">
                Manage Information Systems for Waikiki Business Plaza, Waikiki Shopping Plaza, Top of Waikiki and SKY Waikiki. Research, implement, document and support business projects that can utilize technology to improve various aspects of the business. Maintain account lifecycle creating and manage rights to network resources. Resolve tickets by researching, documenting, and implementing repairs. Provide end-user support on all network resources. Maintains six unique networks, including modems, firewalls, switches, physical servers, virtual servers, wireless access points and network cables. Support, maintain and troubleshoots parking gates, loops, terminals, sensors, database, as well as restaurant point of sale and building/venue audio/video.
            </p>

            <p class="job"><b>Senior Barkeep</b><br></br>
                Santora’s Pizza Pub and Grill – Buffalo, NY<br></br>
                October 2014 - December 2017<br></br>
            </p>

            <p class="left">
                Promoted from within multiple times to senior Barkeep. Served as the company's first impression to customers greeting, conversing, serving and interpreting all requests. Learned drink and food procedures with ability to describe, execute and accurately recommend orders. Organized multiple team members for long hours at one of the busiest sports bars in the Buffalo area. Maintained customer wellbeing by ensuring a safe, enjoyable environment for all patrons. Trusted by management to lock-up and record financial accuracy for daily operation s. Nicknamed "Happy Jack" for positive attitude.
            </p>
    
            <p class="job"><b>Expediter and Server</b><br></br>
                Fisherman’s Catch – Point Lookout, NY<br></br>
                2012 - 2014<br></br>	
            </p>

            <p class="left">
                Promoted from within multiple times to food Expediter. Worked alongside head chef to provide quality control for all products sent out, in addition to organizing runner staff for product accuracy. Fully trained in five positions in order to maintain restaurant efficiency if a position needed to be covered. Served as head chef while chef was on leave.
            </p>
    
            <p class="job"><b>Ocean Lifeguard</b><br></br>
                Lido Beach Towers – Lido Beach, NY<br></br>
                2007 - 2012<br></br>
            </p>

            <p class="left">
                Promoted within from Cabana Boy to Lifeguard. Cared for the maintenance of the beach, dunes, buoys, and jetty while protecting beach swimmers by preventing dangerous swimming situations and saving any swimmers in distress or danger.
            </p>
            <br></br>
        </div>
    )
}

export default WorkPage;