import React from 'react';
import './navbar.css';
import { GoogleLogin } from 'react-google-login';
import { GoogleLogout } from 'react-google-login';

const Navbar = (props) => {

    const responseGoogle = (response) => {
      console.log(response);
    }

    return(
        <header className="header">
            <h2><a onClick={props.home} href="#home">Jack O'Neill</a></h2>
            <nav>
                <li><a onClick={props.home} href="#about">About</a></li>
                <li><a onClick={props.home} href="#Portfolio">Portfolio</a></li>
                <li><a onClick={props.resume} href="#Resume">Resume</a></li>
                <li><a onClick={props.home} href="#Contact">Contact</a></li>
                {props.isLoggedIn
                    ?
                    <li><a onClick={props.logout} href="#home" >Log Out</a></li>
                    :
                    <li><a onClick={props.login} href="#home" >Log In</a></li>
                }
                {props.isLoggedIn
                    ?
                    <li><GoogleLogout
                            clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                            buttonText="Logout"
                            onLogoutSuccess={props.logout}
                            onFailure={responseGoogle}
                        >
                        </GoogleLogout>
                    </li>
                    :
                    <li><GoogleLogin
                            clientId="421072642536-m217ms4llfih74f42lgsqjgi2k8tb680.apps.googleusercontent.com"
                            buttonText="Login"
                            isSignedIn={true}
                            onSuccess={props.login}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                    /></li>

                  }
            </nav>
        </header>
    )
}

export default Navbar;
