import React from 'react';
import './portfolio.css';

const Portfolio = (props) => {
    return (
        <section id="Portfolio" className="Portfolio">
            <h3 className="title">
            Click for more info!</h3>

            <div className="showcase">
                <div onClick={props.resume} className="showitem1">
                    <h3>Resume</h3>
                </div>
                <div onClick={props.hobbies} className="showitem2">
                    <h3>Hobbies</h3>
                </div>
                <div onClick={props.messageBoard} className="showitem3">
                    <h3>Hawaii</h3>
                </div>
                <div onClick={props.cats} className="showitem4">
                    <h3>Tupe & Noto</h3>
                </div>
            </div>
        </section>
    )
}
export default Portfolio;
