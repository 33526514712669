import React from 'react';
import Tupe1 from '../../resources/catsPage/Tupe1.webp';
import Tupe2 from '../../resources/catsPage/Tupe2.webp';
import Tupe3 from '../../resources/catsPage/Tupe3.webp';
import Tupe4 from '../../resources/catsPage/Tupe4.webp';
import Tupe5 from '../../resources/catsPage/Tupe5.webp';
import Tupe6 from '../../resources/catsPage/Tupe6.webp';
import Tupe7 from '../../resources/catsPage/Tupe7.webp';
import Tupe8 from '../../resources/catsPage/Tupe8.webp';
import Tupe9 from '../../resources/catsPage/Tupe9.webp';
import Tupe10 from '../../resources/catsPage/Tupe10.webp';
import Tupe11 from '../../resources/catsPage/Tupe11.webp';

import Noto1 from '../../resources/catsPage/Noto1.webp';
import Noto2 from '../../resources/catsPage/Noto2.webp';
import Noto3 from '../../resources/catsPage/Noto3.webp';
import Noto4 from '../../resources/catsPage/Noto4.webp';
import Noto5 from '../../resources/catsPage/Noto5.webp';
import './cats.css';



const Cats = () => {
    return (
        <section id="Cats" className="cats">
            <h1 className="cats"> Noto (Tabi) & Tupe (American Short Hair) </h1>
            <ul className="grid">
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Noto1 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Noto2 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Noto3 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Noto4 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Noto5 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Tupe1 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Tupe2 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Tupe3 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Tupe4 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Tupe5 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Tupe6 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Tupe7 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Tupe8 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Tupe9 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Tupe10 +")"}}></li>
                <li className="small" loading="lazy" style={{backgroundImage: "url(" + Tupe11 +")"}}></li>

            </ul>
            <ul className="mtg">
              <li className="cardName"><h3>test</h3></li>
            </ul>

        </section>


    )
}
export default Cats
