import React from 'react';
import './buffer.css';

const Buffer = () => {
    return (
        <section id="Buffer" className="buffer">
          <h1 className="buffer"> </h1>
        </section>
    )
}
export default Buffer
