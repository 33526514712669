import React from 'react';
import './messageboard.css';

const MessageBoard = () => {

    return(
        <section className="messageBoard">
          <div claasName="squareBoarder">
            <h2 className="title">Web Tasks</h2>

            <div className="messageBlock">
                <h4 className="submissionContent">Refactor site into React</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Setup auto publishing from Git Repository</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Add mobile compatability</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Add Google Analytics</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Change image format to WEBP</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Host images on CDN</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Incorporate Google 0auth</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Add Project Task Message Board</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Add Project 2</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Add Project 3</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Add Project 4</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Setup social and social links</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Refactor into PWA</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

            <div className="messageBlock">
                <h4 className="submissionContent">Google speed test</h4>
                <h4 className="submissionUser">userName</h4>
            </div>

          </div>
        </section>
    )
}
export default MessageBoard;
