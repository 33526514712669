import React from 'react';
import './hobbies.css';



const Hobbies = () => {
    return (
        <section id="Hobbies" className="hobbies">

            <section className="banner">
                <h2>These are some of my favorite things to do!</h2>
            </section>
            <hr></hr>
            <div>
                <h1>Magic The Gathering</h1>
                <br></br>
                <p>I love playing Magic The Gathering. I lean into the instant speed decks and enjoy non-linear decks that like to catch people off guard. These are some examples of my favorite decks..</p>
            </div>
            <div id="MTGDecks" className="mtgDecks">

                <section id="MTG" className="mtg">
                    <h1>Izzet Dragons</h1>
                    <h1>MTGA (Standard BO1)</h1>
                    <br></br>
                    <h4>Creatures = 12</h4>
                    <ul className="bonecrusherGiant"><h3>4x Bonecrusher Giant</h3></ul>
                    <ul className="brazenBorrower"><h3>4x Brazen Borrower</h3></ul>
                    <ul className="goldspanDragon"><h3>4x Goldspan Dragon</h3></ul>
                    <br></br>
                    <h4>Spells = 22 (8 land/spells)</h4>
                    <ul className="alrundsEpiphany"><h3>4x Alrund's Epiphany</h3></ul>
                    <ul className="cinderclasm"><h3>2x Cinderclasm</h3></ul>
                    <ul className="essenceScatter"><h3>3x Essence Scatter</h3></ul>
                    <ul className="expressiveIteration"><h3>1x Expressive Iteration</h3></ul>
                    <ul className="jwariDisruption"><h3>4x Jwari Disruption</h3></ul>
                    <ul className="magmaOpus"><h3>1x Magma Opus</h3></ul>
                    <ul className="mysticalDispute"><h3>2x Mystical Dispute</h3></ul>
                    <ul className="negate"><h3>1x Negate</h3></ul>
                    <ul className="prismariCommand"><h3>4x Prismari Command</h3></ul>
                    <ul className="sawItComing"><h3>4x Saw It Coming</h3></ul>
                    <ul className="spikefieldHazard"><h3>4x Spikefield Hazard</h3></ul>
                    <br></br>
                    <h4>Lands = 20 (8 land/spells)</h4>
                    <ul className="snowCoveredIsland"><h3>4x Snow-Covered Island</h3></ul>
                    <ul className="snowCoveredMountain"><h3>4x Snow-Covered Mountain</h3></ul>
                    <ul className="riverglidePathway"><h3>4x Riverglide Pathway</h3></ul>
                    <ul className="volatileFjord"><h3>4x Volatile Fjord</h3></ul>
                    <ul className="fabledPassage"><h3>4x Fabled Passage</h3></ul>
                </section>

                <section id="MTG" className="mtg">
                    <h1>Grixis Death's Shadow</h1>
                    <h1>Modern</h1>
                    <br></br>
                    <h4>Companion = 1</h4>
                    <ul className="lurrusoftheDreamDen"><h3>1x Lurrus of the Dream-Den</h3></ul>
                    <br></br>
                    <h4>Creatures = 18</h4>
                    <ul className="deathsShadow"><h3>4x Death's Shadow</h3></ul>
                    <ul className="dragonsRageChanneler"><h3>4x Dragon's Rage Channeler</h3></ul>
                    <ul className="ragavanNimblePilferer"><h3>4x Ragavan, Nimble Pilferer</h3></ul>
                    <ul className="scourgeoftheSkyclaves"><h3>4x Scourge of the Skyclaves</h3></ul>
                    <ul className="snapcasterMage"><h3>2x Snapcaster Mage</h3></ul>
                    <br></br>
                    <h4>Spells = 22 (8 land/spells)</h4>
                    <ul className="dismember"><h3>1x Dismember</h3></ul>
                    <ul className="counterspell"><h3>4x Counterspell</h3></ul>
                    <ul className="fatalPush"><h3>2x Fatal Push</h3></ul>
                    <ul className="inquisitionofKozilek"><h3>2x Inquisition of Kozilek</h3></ul>
                    <ul className="lightningBolt"><h3>4x Lightning Bolt</h3></ul>
                    <ul className="stubbornDenial"><h3>1x Stubborn Denial</h3></ul>
                    <ul className="temurBattleRage"><h3>2x Temur Battle Rage</h3></ul>
                    <ul className="thoughtseize"><h3>4x Thoughtseize</h3></ul>
                    <br></br>
                    <h4>Artifacts = 4</h4>
                    <ul className="MishrasBauble"><h3>4x Mishra's Bauble</h3></ul>
                    <br></br>
                    <h4>Lands = 18</h4>
                    <ul className="bloodCrypt"><h3>2x Blood Crypt</h3></ul>
                    <ul className="bloodstainedMire"><h3>4x Bloodstained Mire</h3></ul>
                    <ul className="island"><h3>1x Island</h3></ul>
                    <ul className="pollutedDelta"><h3>4x Polluted Delta</h3></ul>
                    <ul className="scaldingTarn"><h3>3x Scalding Tarn</h3></ul>
                    <ul className="steamVents"><h3>2x Steam Vents</h3></ul>
                    <ul className="swamp"><h3>1x Swamp</h3></ul>
                    <ul className="wateryGrave"><h3>1x Watery Grave</h3></ul>
                </section>

                <section id="MTG" className="mtg">
                    <h1>Izzet Emerge</h1>
                    <h1>Favorite (Kaladesh Standard)</h1>
                    <br></br>
                    <h4>Creatures = 16</h4>
                    <ul className="advancedStitchwing"><h3>4x Advanced Stitchwing</h3></ul>
                    <ul className="elderDeepFiend"><h3>4x Elder Deep-Fiend</h3></ul>
                    <ul className="prizedAmalgam"><h3>4x Prized Amalgam</h3></ul>
                    <ul className="stitchwingSkaab"><h3>4x Stitchwing Skaab</h3></ul>
                    <br></br>
                    <h4>Spells = 21</h4>
                    <ul className="catharticReunion"><h3>4x Cathartic Reunion</h3></ul>
                    <ul className="feveredVisions"><h3>4x Fevered Visions</h3></ul>
                    <ul className="fieryTemper"><h3>4x Fiery Temper</h3></ul>
                    <ul className="kozileksReturn"><h3>3x Kozilek's Return</h3></ul>
                    <ul className="negate"><h3>2x Negate</h3></ul>
                    <ul className="tormentingVoice"><h3>4x Tormenting Voice</h3></ul>
                    <br></br>
                    <h4>Lands = 23</h4>
                    <ul className="island"><h3>7x Island</h3></ul>
                    <ul className="mountain"><h3>5x Mountain</h3></ul>
                    <ul className="sanctumofUgin"><h3>3x Sanctum of Ugin</h3></ul>
                    <ul className="spirebluffCanal"><h3>4x Spirebluff Canal</h3></ul>
                    <ul className="wanderingFumarole"><h3>4x Wandering Fumarole</h3></ul>
                </section>

            </div>
            <hr></hr>
        </section>
    )
}
export default Hobbies
