import React from 'react';
import './hero.css';
import Background from '../../resources/Maui1.webp';

const Hero = () => {

    return(
        <section className="hero">
            <div className="background-image" style={{backgroundImage: "url(" + Background +")"}}></div>
            <div className="hero-content-area">
                <h1>Aloha!</h1>
                <h3>Welcome to my site!</h3>
            </div>
        </section>
    )
}
export default Hero;
