import React, { Component } from 'react';
import './resume.css';
import WorkPage from './workpage/workpage';
import QualificationPage from './qualificationpage/qualificationpage';
import EducationPage from './educationpage/educationpage';

class Resume extends Component {

    state = {
        workContent: true,
        qulaificationContent: false,
        educationContent: false,

    }

    workHandler = () => {
        this.setState({
            workContent: true,
            qulaificationContent: false,
            educationContent: false,

        })
    }

    qulaificationHandler = () => {
        this.setState({
            workContent: false,
            qulaificationContent: true,
            educationContent: false,

        })
    }

    educationHandler = () => {
        this.setState({
            workContent: false,
            qulaificationContent: false,
            educationContent: true,

        })
    }


    render() {
        return (
            <section id="Resume" class="Resume">
                <h3 class="title">Resume:</h3>
                <hr></hr>
                <div class="ResumeTopics">
                    <p onClick={this.workHandler} class="exp"><b>Work Experience</b></p>
                    <p onClick={this.qulaificationHandler} class="exp"><b>Qualifications</b></p>
                    <p onClick={this.educationHandler} class="exp"><b>Education</b></p>
                </div><br></br>

                {this.state.educationContent ? <EducationPage /> : null}
                {this.state.qulaificationContent ? <QualificationPage /> : null}
                {this.state.workContent ? <WorkPage /> : null}

                <br></br>
                <a href="./Jack O'Neill.pdf" class="btn" download>Download as PDF</a>
            </section>
        );
    }
}
export default Resume;
