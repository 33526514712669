import React from 'react';

const EducationPage = () => {
    return (
        <div class="workExp">
            <p class="job"><b>Bachelor of Science in Computer Information Systems</b><br></br>
            Buffalo State College<br></br>
            December 2017<br></br>
            </p>
            <p class="left">
            President of Buffalo State Information Processors Association. Offered students a safe alternative to spend free time. Organized LAN parties and competed in regional video game tournaments representing Buffalo State College. Attended school events and college fairs to recruit new members.            </p>
        </div>
    )
}
export default EducationPage;